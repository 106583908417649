import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import { Title, Container, Image, Text } from "../components/atoms"
import { Sidebar, Share } from "../components/molecules"

const NewsLayout = styled(Container)`
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    @media (min-width: 768px) {
        display: flex;
        align-items: flex-start;
    }
    @media (min-width: 768px) {
        margin-top: 4em;
        margin-bottom: 4em;
    }
`

const NewsSidebar = styled(Sidebar)`
    @media (min-width: 768px) {
        flex: 0 0 30%;
    }
`

const ArticleContent = styled.section`
    @media (min-width: 768px) {
        flex: 0 0 70%;
        padding-right: 2em;
    }
    @media (min-width: 960px) {
        padding-right: 4em;
    }
`

const Post = ({ data: { wpPost }, location }) => {
    const { title, excerpt, hero, content } = wpPost;
    const sharableUrl = location.href;
    return (
        <Layout>
            <main>
                <NewsLayout forwardedAs="article" maxWidth="73.125em" gutter>
                    <ArticleContent>
                        <Title as="h1" marginBottom>{title}</Title>
                        <Text content={excerpt} />
                        <Image image={hero.image.localFile.childImageSharp.gatsbyImageData} alt={hero.image.altText} />
                        <Text content={content} />
                    </ArticleContent>
                    <NewsSidebar>
                        <Share url={sharableUrl} emailSubject={`${title} - Ernehale Colts`} />
                    </NewsSidebar>
                </NewsLayout>
            </main>
        </Layout>
    )
}

export const query = graphql`
    query ArticleQuery($id: String) {
        wpPost(id: {eq: $id}) {
            title
            excerpt
            content
            hero {
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    altText
                }
            }
        }
    }
`

export default Post